<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ product.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <td>Название</td>
                                    <td>Артикул</td>
                                    <td></td>
                                    <!-- <td></td> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in productFabrics" :key="item.id">
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.code }}</td>
                                    <td align="right">
                                        <v-icon color="primary" @click="remove(item.product_fabric_id)">mdi-trash-can</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn color="primary" class="mr-3" @click="create" v-if="filteredFabrics.length">
                            <v-icon>mdi-plus</v-icon> Добавить
                        </v-btn>
                        <v-btn color="light" class="mr-3" @click="goBack" >Назад</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Ткань
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="editProductFabric.fabric_id"
                                    label="Ткань"
                                    :items="filteredFabrics"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '@/components/products/ProductTabLinks';

    export default {
        name: 'ProductFabrics',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                editModal: false,
                editProductFabric: {}
            };
        },

        computed: {
            ...mapState('products', {
                product: state => state.entity
            }),
            ...mapState('fabrics', {
                fabrics: state => state.entities
            }),
            productFabrics() {
                return this.product && this.product.fabrics ? this.product.fabrics : [];
            },
            filteredFabrics() {
                if(this.product && this.product.fabrics) {
                    const fabricIds = this.product.fabrics.map(item => item.id);
                    return this.fabrics.filter(item => {
                        return fabricIds.indexOf(item.id) < 0;
                    });
                }
                return this.fabrics;
            }
        },

        async mounted() {
            await store.dispatch('products/get', { id: this.$route.params.id });
            await store.dispatch('fabrics/fetch');
        },

        methods: {
            create () {
                this.editProductFabric = {
                    product_id: this.product.id,
                    fabric_id: ''
                };
                this.editModal = true;
            },
            edit(item) {
                this.editProductFabric = {
                    id: item.product_fabric_id,
                    product_id: this.product.id,
                    fabric_id: item.id
                };
                this.editModal = true;
            },
            async save() {
                const { id } = this.$route.params;
                try {
                    store.commit('productFabrics/SET_ENTITY', this.editProductFabric);
                    await store.dispatch('productFabrics/save', { product_id: id });
                    await store.dispatch('products/get', { id });
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },
            async remove(product_fabric_id) {
                const { id } = this.$route.params;
                try {
                    await store.dispatch('productFabrics/delete', { product_id: id, id: product_fabric_id });
                    await store.dispatch('products/get', { id });
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                const { id } = this.$route.params;
                if(id) {
                    this.$router.push(`/products/${id}/detail`);
                } else {
                    this.$router.push('/products');
                }
            },
        },
    };
</script>